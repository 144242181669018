.margin-custom{
    margin-top: 47px !important;
}
.serviceContainerCards{
        height: 20vh;
}
li {
    list-style-type: none;
  }
.serviceContainerTotal {
    height: 60vh;
}
.subtitle{
    font-size: 25px !important;
}
.title{
    font-size: 30px !important;
}
.textP{
    font-size: 20px !important;
}
@media only screen and (min-width: 1920px) {
    .serviceContainerCards{
        height: 290vh;
    }
    .subtitle{
        font-size: 20px !important;
    }
    .title{
        font-size: 40px !important;
    }
    .textP{
        font-size: 20px !important;
    }
}
@media only screen and (min-width: 1600px) and (max-width: 1919px){
    .serviceContainerCards{
        height: 360vh;
    }
    .subtitle{
        font-size: 20px !important;
    }
    .title{
        font-size: 40px !important;
    }
    .textP{
        font-size: 20px !important;
    }
}
@media only screen and (min-width: 1200px) and (max-width: 1599px){
    .serviceContainerCards{
        height: 550vh;
    }
    .subtitle{
        font-size: 20px !important;
    }
    .title{
        font-size: 40px !important;
    }
    .textP{
        font-size: 20px !important;
    }
}
/* Escritorio grande */
@media only screen and (min-width: 992px) and (max-width: 1199px){
    .serviceContainerCards{
        height: 470vh;
    }
}

/* Escritorio pequeño / tablet */
@media only screen and (min-width: 768px) and (max-width: 991px){
    .margin-custom{
        margin-top: 260px !important;
    }
    .serviceContainerCards{
        height: 810vh;
    }
    .subtitle{
        font-size: 20px !important;
    }
    .title{
        font-size: 40px !important;
    }
    .textP{
        font-size: 20px !important;
    }
}

/* Tablets y phablets */
@media only screen and (min-width: 576px) and (max-width: 767px){
    .serviceContainerCards{
        height: 875vh;
    }
    .subtitle{
        font-size: 20px !important;
    }
    .title{
        font-size: 40px !important;
    }
    .textP{
        font-size: 20px !important;
    }
}

@media only screen and (min-width: 451px) and (max-width: 575px){
    .serviceContainerCards{
        height: 1100vh;
    }
    .subtitle{
        font-size: 15px !important;
    }
    .title{
        font-size: 25px !important;
    }
    .textP{
        font-size: 11px !important;
    }
}
/* Teléfonos */
@media only screen and (min-width: 392px) and (max-width: 450px){
    .serviceContainerCards{
        height: 1300vh;
    }
    .subtitle{
        font-size: 15px !important;
    }
    .title{
        font-size: 25px !important;
    }
    .textP{
        font-size: 11px !important;
    }
}

@media only screen and (min-width: 363px) and (max-width: 391px){
    .serviceContainerCards{
        height: 1420vh;
    }
    .subtitle{
        font-size: 15px !important;
    }
    .title{
        font-size: 25px !important;
    }
    .textP{
        font-size: 11px !important;
    }
}

@media only screen and (max-width: 348px) and (max-width: 362px){
    .serviceContainerCards{
        height: 1550vh;
    }
    .subtitle{
        font-size: 15px !important;
    }
    .title{
        font-size: 25px !important;
    }
    .textP{
        font-size: 11px !important;
    }
}

@media only screen and (max-width: 347px){
    .serviceContainerCards{
        height: 1500vh;
    }
    .subtitle{
        font-size: 15px !important;
    }
    .title{
        font-size: 25px !important;
    }
    .textP{
        font-size: 11px !important;
    }
}

@media only screen and (max-height: 700px) and (max-width: 391px){
    .serviceContainerCards{
        height: 1550vh;
    }
}