@config "./../tailwind.config.js";

@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Geologica:wght@300&family=Montserrat:wght@300&family=Poppins:ital,wght@0,500;1,200&display=swap');
:root{
  --bg-color: #ffffff;
  --text-color: #001369;
  --main-color: #004AAD;
  --secundary-color: #001369;
  --terceary-color: #FFAA29;
  --forty-color: #FFBD59;
}
*{
  font-family: 'Geologica', sans-serif;
  font-family: 'Montserrat', sans-serif;
  font-family: 'Poppins', sans-serif;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
::-webkit-scrollbar{
  width: 0;
}
body {
  min-height: 100vh;
  background: var(--bg-color);
  color: var(--text-color);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a{
  text-decoration: none;
}