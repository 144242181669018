.contactContainer {
    padding-top: 45px;
    width: 80%;
    margin:auto
}

.bannerHeight{
    height: 60vh;
}
.button-ln{
    border: 1px solid #001369;
    border-radius: 23px;
    width: 40px;
    height: 40px;
}
.button i{
    font-size: 25px;
}
s.container{
    margin-top: 100vh;
}

.textTitle{
    font-size: 2.25rem/* 36px */;
    line-height: 2.5rem/* 40px */;
}

.bg-image-custom{
    background-image: url('./../../assets/a1.webp');
}

.margin-custom{
    margin-top: 47px !important;
}
.margin-top-custom{
    margin-top: 250px;
}

@media only screen and (min-width: 1600px){
.contactInfo{
    height: 150vh;
}
.contactTotal {
    height: 210vh;
}
.margin-top-custom{
    margin-top: 555px;
}
}
@media only screen and (min-width: 1200px) and (max-width: 1599px){
.contactInfo{
    height: 190vh !important;
    top: 25%;
}
.contactTotal {
    height: 250vh !important;
}
.margin-top-custom{
    margin-top: 600px !important;
}
}
/* Escritorio grande */
@media only screen and (min-width: 992px) and (max-width: 1199px){
.contactInfo{
    height: 230vh;
}
.contactTotal {
    height: 290vh;
}
.margin-top-custom{
    margin-top: 680px;
}
}

/* Escritorio pequeño / tablet */
@media only screen and (min-width: 768px) and (max-width: 991px){
.margin-custom{
    margin-top: 47px !important;
}
.contactInfo{
    height: 180vh;
    top: 25%;
}
.contactTotal {
    height: 230vh;
}
.margin-top-custom{
    margin-top: 800px;
}
}

/* Tablets y phablets */
@media only screen and (min-width: 576px) and (max-width: 767px){
.contactInfo{
    height: 190vh;
    top: 25%;
}
.contactTotal {
    height: 230vh;
}
.margin-top-custom{
    margin-top: 800px;
}
}

/* Teléfonos */
@media only screen and (max-width: 575px){
    .contactInfo{
        height: 200vh;
        top: 20%;
    }
    .contactTotal {
        height: 270vh;
    }
    .margin-top-custom{
        margin-top: 900px;
    }
    .textTitle{
        font-size: 1.75rem/* 36px */;
        line-height: 1.9rem/* 40px */;
    }
}

@media only screen and (max-width: 391px){
    .contactInfo{
        height: 200vh;
        top: 20%;
    }
    .contactTotal {
        height: 270vh;
    }
    .margin-top-custom{
        margin-top: 1000px;
    }
    .addressInfo{
        padding-left: 0px !important;
    }
    .margin-custom{
        margin-top: 20px !important;
    }
    .textTitle{
        font-size: 1.65rem/* 36px */;
        line-height: 1.7rem/* 40px */;
    }
} 

@media only screen and (max-height: 700px) and (max-width: 391px){
    .contactInfo{
        height: 250vh;
        top: 20%;
    }
    .bannerHeight{
        height: 70vh;
    }
    .contactTotal {
        height: 300vh;
    }
    .margin-top-custom{
        margin-top: 1000px;
    }
    .margin-custom{
        margin-top: 20px !important;
    }
    .textTitle{
        font-size: 1.65rem/* 36px */;
        line-height: 1.7rem/* 40px */;
    }
}