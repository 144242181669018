.secondSection{
    margin: 110px 10% 100px 10%;
}

.serviceContainer{
    padding-top: 45px;
    width: 100%;
    margin:auto
}
.serviceContainerBg{
    background-image: url('../../assets/a1.webp');
}

.margin-secundarySection{
    height: 60vh;
}

.contactContainerHome{
    height: 100vh;
}
/* Escritorio grande */
@media only screen and (min-width: 992px) and (max-width: 1199px){
    .contactContainerHome{
        height: 120vh;
    }
}

/* Escritorio pequeño / tablet */
@media only screen and (min-width: 768px) and (max-width: 991px){
    .contactContainerHome{
        height: 140vh;
    }
}
@media only screen and (min-width: 576px) and (max-width: 767px){
    .margin-secundarySection{
        height: 80vh;
    }
    .contactContainerHome{
        height: 150vh;
    }
}

@media only screen and (min-width: 451px) and (max-width: 575px){
    .margin-secundarySection{
        height: 90vh;
    }
    .contactContainerHome{
        height: 160vh;
    }
}
/* Teléfonos */
@media only screen and (min-width: 392px) and (max-width: 450px){
    .margin-secundarySection{
        height: 90vh;
    }
    .contactContainerHome{
        height: 170vh;
    }
}

@media only screen and (max-width: 391px){
    .margin-secundarySection{
        height: 90vh;
    }
    .contactContainerHome{
        height: 180vh;
    }
}