
header{
    position: fixed;
    width: 100%;
    top: 0;
    right: 0;
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: space-around;
    /* background: #060c2d8f; */
    background: #ffffffe8;
    padding: 17px 0px 19px 10px;
    transition: all .50s ease;
}
ul {
    list-style-type: none; 
}
.buttomMenu{
    margin-top: 17px;
}
.logo{
    display: flex;
    align-items: center;
}
.logo i{
    color: var(--main-color);
    font-size: 28px;
    margin-right: 3px;
}

.logo span{
    color: var(--text-color);
    font-size: 1.7rem;
    font-weight: 600;
}

.main a.active{
    color: var(--main-color);
}
.main {
    display: flex;
    align-items: center;
    margin-left: 60px;
}
.main a{
    margin-right: 25px;
    margin-left: 10px;
    color: var(--text-color);
    font-size: 1.1rem;
    font-weight: 500;
    transition: all .50s ease;
}

.user {
    display: flex;
    align-items: center;
    padding: 0px !important;
}

.logo i{
    color: var(--main-color);
    font-size: 28px;
    margin-right: 7px;
}

.main a:hover {
    color: var(--main-color);
}

#menu-icon{
    font-size: 35px;
    color: var(--text-color);
    cursor: pointer;
    z-index: 10001;
    display: none;
}
@media (max-width: 1280px) {
    header {
        padding: 14px 2%;
        transition: .2s;
    }
    .navbar a{
        padding: 5px 0;
        margin: 0px 20px
    }
}

@media (max-width: 1090px) {
    #menu-icon {
        display: block;
        font-size: 29px;
    }
    .navbar{
        position: absolute;
        top: 100%;
        right: -100%;
        width: 270px;
        height: 32vh;
        background: var(--secundary-color);
        flex-direction: column;
        justify-content: flex-start;
        border-radius: 10px;
        transition: all .50s ease;
    }
    .navbar a {
        display: block;
        margin: 17px 0;
        padding: 0px 25px;
        transition: all .50s ease;
    }
    .navbar a:hover{
        color: var(--bg-color);
        transform: translateY(5px);
    }
    .navbar a.active{
        color: var(--secundary-color);
    }
    .navbar.open{
        right: 2%;
    }
    .logo span{
        color: var(--text-color);
        font-size: 1.4rem;
    }

    .divider {
        border-bottom: 1px solid var(--secundary-color); /* Cambia el color y el estilo del borde según tus preferencias */
        padding-bottom: 10px; 
    }
}