.image{
    width: 100%;
}

.text-color-custom{
    color: #fff;
}

@media only screen and (max-width: 549px){
    .smallxx {
        font-size: 2.35rem
    }
}