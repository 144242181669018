.accordion-item {
    border-radius: 0.5rem;
    overflow: hidden;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -2px rgba(0, 0, 0, 0.1);
  }
  
  .accordion-button {
    background-image: linear-gradient(to right, #001369 0%, #0027b4 25%, #1a3f95 50%, #0027b4 75%, #001369 100%);
    color: white;
  }
  
  .accordion-button:hover {
    background-image: linear-gradient(to right, #001369 0%, #0037d5 25%, #2a4fb8 50%, #0037d5 75%, #001369 100%);
  }
  
  .icon {
    transition: transform 0.3s ease;
  }
  
  .accordion-content {
    background-color: #f0f4f8;
  }
  